@import "txt";
@import "elements";




#welcome .container {
	height: 100vh;
	margin: 0 auto;
	text-align: center;
	padding-left: 0 !important;

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;


}

#welcome .head {
	width: 50%;
}

.welcome-image {
	position: absolute;
	
}

#welcome-strawberry {
	top: 0;
	left: 0;
	height: 25vw;
}

#welcome-salad {
	bottom: -200px;
	left: 0;
	height: 35vw;

	margin-bottom: 10rem;
}

#welcome-mint {
	top: 0;
	right: 0;
	height: 25vw;
}

#welcome-tomato {
	bottom: -200px;
	right: 0;
	height: 35vw;

	margin-bottom: 10rem;
}

.grolli-air-render {
 	margin: 0 auto;
 	text-align: center;
 	width: 30vw;
 	object-fit: cover;
 }

 #welcome .btn {
 	margin-bottom: 3rem;
 }



.icons img {
	transition: all 0.5s cubic-bezier(.2,.68,.36,1.01);
	height: 7rem;
}

.icons img:hover {
	transform: scale(1.03);
	}





@media (min-width: 768px) and (max-width: 1199px)  {
	#welcome .container {
		margin-bottom: 20rem;
	}

	.grolli-air-render {
 	width: 40vw;
 	}

 	#welcome .head {
 		width: 500px;
 	}

 	#welcome .btn {
		margin-bottom: 6rem;
	}

	.icons img {
 	height: 5rem;
 	margin-right: 1rem;
 	}
}


@media (max-width: 767px) {
		#welcome-strawberry {
	top: 0;
	left: 0;
	height: 35vw;
	}

	#welcome-salad {
		bottom: -50px;
		height: 45vw;
	}

	#welcome-mint {
		top: 0;
		right: 0;
		height: 35vw;
	}

	#welcome-tomato {
	bottom: -50px;
	height: 45vw;
	}

	#welcome .container {
		margin-bottom: 10rem
	}

	#welcome .head {
 		width: 85%;
 		text-align: center;
 	}

 	#welcome .btn {
		margin-bottom: 6rem;
	}

	.grolli-air-render {
 	margin: 0 auto;
 	text-align: center;
 	width: 80vw;
 	}
}



#eco .container {
	margin-bottom: 20rem;

	text-align: center;

}

#eco .container .head {
	margin: 0 auto;
	width: 50%;
}

#eco .btn {
	margin-bottom: 10rem;
}


#eco .card-grid {
	display: grid;
	grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 60vh;
    width: 100%;
 	gap: 6rem;
 	padding: 6rem;
    overflow-y: visible;
}

#eco-1 {
	grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 2;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 3rem;

    background-image: url(../img/eco/farm.webp);
    background-size: cover;
    background-position: center;
}



#eco-2 {
	grid-column-start: 6;
    grid-column-end: 12;
    grid-row-start: 2;
    grid-row-end: 3;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 3rem;

    background-image: url(../img/eco/ferti.webp);
    background-size: cover;
    background-position: top;
}

#eco-3 {
	grid-column-start: 8;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 2;

    background-image: url(../img/eco/light.webp);
    background-size: cover;
    background-position: center;

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 3rem;
}

#eco-3 .headcard {
	color: var(--whiteColor);
}

#eco-4 {
	grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 3;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 3rem;

    background-image: url(../img/eco/seeds.webp);
    background-size: cover;
    background-position: center;
}

#eco-5 {
	grid-column-start: 12;
    grid-column-end: 16;
    grid-row-start: 1;
    grid-row-end: 3;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 3rem;

    background-image: url(../img/eco/app.webp);
    background-size: cover;
    background-position: center;
}



@media (min-width: 768px) and (max-width: 1199px) {
	#eco .slider-mob {
		display: grid !important;
	}

	#eco .card-grid {
		grid-template-columns: repeat(16, 27px);
 		grid-template-rows: repeat(2,250px);
		gap: 6rem;
		padding-right: 6rem;
		height: 550px;
		overflow: auto;
	}

	 	#eco .card-grid {
 		grid-gap: 4rem;
 	}

 	#eco .container .head {
 		width: 70%
 	}


 	#eco-1 {
	grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
}


#eco-2 {
	grid-column-start: 6;
    grid-column-end: 11;
    grid-row-start: 2;
    grid-row-end: 3;

}

#eco-3 {
	grid-column-start: 7;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 2;

}


#eco-4 {
	grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 3;
}

#eco-5 {
	grid-column-start: 11;
    grid-column-end: 16;
    grid-row-start: 1;
    grid-row-end: 3;
}
}

@media (max-width: 767px) {


	#eco .btn {
		margin-left: 6rem
	}

	#eco .container {
 		align-items: flex-start;
 		justify-content: flex-start;
 		text-align: left;
 	}


	#eco .container .head {
		text-align: left;
		width: 80%;
		margin-left: 6rem;
	}


	#eco .container .subhead {
		text-align: left;
		margin-left: 6rem;
	}



 	#eco .card-grid {
 		grid-template-columns: repeat(16, 18px);
 		grid-template-rows: repeat(2,200px);
		gap: 6rem;
		padding-right: 6rem;
		height: 450px;
		width: 100%
 	}


 	#eco-1 {

 	grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;	

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;


    background-image: url(../img/eco/farm.webp);
    background-size: cover;
    background-position: center;
}



#eco-2 {
	grid-column-start: 5;
    grid-column-end: 11;
    grid-row-start: 2;
    grid-row-end: 3;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    background-image: url(../img/eco/ferti.webp);
    background-size: cover;
    background-position: top;

}

#eco-3 {

	grid-column-start: 7;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 2;


    background-image: url(../img/eco/light.webp);
    background-size: cover;
    background-position: center;

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}



#eco-4 {

	grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;


    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    background-image: url(../img/eco/seeds.webp);
    background-size: cover;
    background-position: -90px 0;

    text-align: left;

}

#eco-5 {

	grid-column-start: 11;
    grid-column-end: 16;
    grid-row-start: 1;
    grid-row-end: 3;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 3rem;

    background-image: url(../img/eco/app.webp);
    background-size: cover;
    background-position: center;
}

}



#abi .container {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: 20rem;
}

#abi .head {
	width: 50%;
}



#abi .icons-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 6rem;
}


#abi .card-grid {
 	display: grid;
 	grid-template-columns: 1fr 3fr;
 	gap: 6rem;
 	padding: 6rem;
 }

#abi .card-grid .card {
	padding: 3rem;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	text-align: center;
}

#abi .card-grid .card-bg {
	background-image: url(../img/abi/render.webp);
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;
}

#abi .icon-card {
  height: 8rem;
}




.gallery-container {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;

}

#gallery-hid {
	display: none;
}

#gallery-btn-show  {
	z-index: 9999;
	margin-top: 5rem;
	margin-bottom: 10rem;
}



.gallery-wrapper {
	padding: 6rem;
	padding-bottom: 0rem;
}

.gallery {
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 10px;
    width: 100%;
  	grid-gap: 6rem;

}

.gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    transition: all 0.5s cubic-bezier(.2,.68,.36,1.01);   
}

.gallery__img:hover {
	transform: scale(1.01);
}


.gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
}

.gallery__item--2 {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 3;
}

.gallery__item--3 {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 6;
}

.gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 6;
}

@media (min-width: 768px) and (max-width: 1199px) {
	 	#abi .head {
 		width: 70%;
 		margin-bottom: 7rem;
 		text-align: center;
 	}

 	#abi .card-grid .card-bg {
 		background-position: -26rem
 	}

		.gallery-mob-wrapper {
		display: none;
	}
	 	 #abi .container {
 		display: flex;
 		flex-direction: column;
 		align-items: center;
 		
 	}

 	#abi .card-grid {
 	gap: 4rem;
 }
  	#abi .icons-grid {
 	gap: 4rem;
 }

.gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
}

.gallery__item--2 {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 3;
}

.gallery__item--3 {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 6;
}

.gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 6;
}


}

@media (max-width: 767px) {
	#abi .container {
		margin-bottom: 20rem;
		display: block;
		text-align: left;
	}

	#abi .icons-grid {
		grid-gap: 4rem;
		padding-left: 6rem;
	}


 	#abi .head {
 		width: 90%;
 		margin-left: 6rem
 	}

 	#abi .subhead {
 		margin-left: 6rem
 	}

	#abi .blur {
		display: none;
	}

	#abi .card-grid {
		grid-template-columns: repeat(1, 1fr);
		padding: 0;
		width: 90%;
		margin-bottom: 10rem
	}



 	.gallery-mob  {
 		display: grid;
 		grid-template-columns: repeat(8, 280px) 50px;
		gap: 6rem;
		padding-right: 6rem;
		padding-left: 6rem;
		width: 100%;
		height: 280px

 	}

 	.gallery__item {
 		height: 250px
 	}

 	.gallery__item-1{
 		background-image: url(../img/abi/render.webp);
		background-size: cover;
		background-position: right;
 	}
}



#app .container {
	text-align: center;
	margin-bottom: 20rem;
	padding-bottom: 10rem;
	overflow: hidden;
}

#app .head {
	width: 70%;
	margin: auto;
}

#app .btn {
	margin-top: 0rem;
}

.app-wrapper {
	position: relative;
	width: 100%;
	height: 100rem;
}

.phone {
	background-image: url(../img/appl/phone.webp);
	background-size: cover;
	background-position: center;

	width: 60%;
	height: 100rem;

	margin: 0 auto;
}

.numbers {
	position: absolute;
	top: 280px;
	right: 160px;
}

.numbers .card {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;

	cursor: pointer;

	width: 15rem;

	margin-bottom: 3rem;
}

.numbers .card .info {
	font-family: 'Stolzl';
	font-style: normal;
	line-height: 105%;
	font-size: 1.2rem;
	font-weight: 120;
	opacity: 80%;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: var(--blackColor);

	margin-bottom: 0.5rem;
}

.numbers .card .num {
	font-family: 'Stolzl';
	font-style: normal;
	line-height: 105%;
	font-weight: 120;
	font-size: 3rem;
	letter-spacing: -0.03em;
	color: var(--blackColor);
}

.numbers .num-box {
	display: flex;
}

.numbers .num-txt {
	margin-left: 1rem;
}

.num2 {
	display: none;
}

.graphic {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	padding: 3rem;
	width: 44rem;

	position: absolute;
	top: 100px;
	right: 160px;

}

#graphic-2 {
	display: none;
}

.graphic img{
	height: 14rem;
}


.graphic-txt  {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: space-between;
	height: 14rem;
	cursor: pointer;
}

.graphic-txt .num {
	font-family: 'Stolzl';
	font-style: normal;
	line-height: 105%;
	font-weight: 120;
	font-size: 3.5rem;
	letter-spacing: -0.03em;
	color: var(--blackColor);
	margin-bottom: 0.5rem;
}

.graphic-txt .info {
	font-family: 'Stolzl';
	font-style: normal;
	line-height: 105%;
	font-size: 1.8rem;
	font-weight: 120;
	opacity: 80%;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: var(--blackColor);
}

.graphic-txt .button {
	background-color: var(--grayColor);
	padding: 0.5rem;
	font-family: 'Stolzl';
	font-style: normal;
	line-height: 105%;
	font-size: 1.5rem;
	font-weight: 120;
	opacity: 80%;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: var(--whiteColor);
	transition: all 0.5s cubic-bezier(.2,.68,.36,1.01);
	cursor: pointer;
	border-radius: 2px;
}

.graphic-txt .button:hover {
	transform: scale(1.1);
}

.sliders {
	width: 40rem;
	padding: 4rem;
	text-align: left;

	position: absolute;
	bottom: 100px;
	left: 150px;


}

#slide-1 {
	margin-bottom: 5rem;
}

.slider-subhead {
	font-family: 'Stolzl';
	font-style: normal;
	font-weight: 120;
	line-height: 105%;
	font-size: 2rem;
	font-weight: 100;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: var(--grayColor);
	 margin: 0;
}

.slider-opthead {
	font-family: 'Stolzl';
	font-style: normal;
	line-height: 105%;
	font-weight: 120;
	font-size: 3rem;
	letter-spacing: -0.03em;
	color: var(--blackColor);
	margin-bottom: 0.5rem;
}

.line-wrapper {
	position: relative;
	height: .7rem;
	width: 100%;

	margin-top: 2rem;
	margin-bottom: 2rem;
}

#slide-1 .gradient-line {
	width: 64%;
}
#slide-2 .gradient-line {
	width: 42%;
}

.gradient-line {
	position: absolute;
	height: .7rem;
	background: linear-gradient(269.99deg, #FFA800 0.01%, #F3294D 27.61%, #EE24DA 48.44%, #7329EB 77.08%, #2352F9 99.99%);
	border-radius: 30px;
}

.white-line {
	position: absolute;
	height: .7rem;
	width: 100%;
	background: #F8F8FF;
	box-shadow: -2px 2px 4px rgba(221, 221, 227, 0.2), 2px -2px 4px rgba(221, 221, 227, 0.2), -2px -2px 4px rgba(255, 255, 255, 0.9), 2px 2px 5px rgba(221, 221, 227, 0.9);
	border-radius: 30px;
}

.circle {
	width: 4rem;
	height: 4rem;
	box-shadow: -32px 10px 13px rgba(0, 0, 95, 0.02), -18px 6px 11px rgba(0, 0, 95, 0.08), -2px 1px 5px rgba(0, 0, 95, 0.15), 0px 0px 0px rgba(0, 0, 95, 0.15), inset 0px -1px 3px 1px rgba(0, 0, 95, 0.12);
	background: var(--whiteColor);
	border-radius: 5rem;
	position: absolute;
	top: -1.6rem;
	transition: all 0.5s cubic-bezier(.2,.68,.36,1.01);;
}

.circle:hover {
	transform: scale(1.1);
}

#slide-1 .circle {
	margin-left: 59%;
}

#slide-2 .circle {
	margin-left: 39%;
}





@media (max-width: 767px) {
	.numbers, .graphic, .sliders {
 		right: 30px !important
 	}

 	.phone {
 		width: 100%
 	}

 	.numbers {
 		top: 120px
 	}

 	.graphic {
 		top: 0;
 	}

 	.input-mail {
 		width: 250px
 	}

 	.phone {
 		margin: 0;
 		position: absolute;
 		left: -15rem;
 	}

 	.numbers {
 		top: 120px
 	}

 	.graphic {
 		top: 0
 	}

 	.sliders {
 	bottom: 0;
 	left: inherit;

 	}

 	#app .btn{
 		margin-top: 10rem
 	}

}



#info .container {
	text-align: center;
	margin-bottom: 30rem;
}

#info .container .head {
	width: 50%;
	margin:  auto;
	margin-bottom: 6rem;
}


#vegan {
 	display: grid;
 	grid-template-columns: repeat(4, 1fr);
 	grid-template-rows: repeat(2, 1fr);
 	grid-gap: 6rem;
 	padding: 4rem;
}

.veg {
 	height: 20rem
 	}

#vegan  .numcard {
	grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 7rem;
}

#vegan  .numcard .card-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2rem;
}

#vegan  .numcard .card-wrapper .bignum {
	margin-right: 3rem;
}

#veg-card-1 {
	grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
}


#veg-card-2 {
	grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
}


#veg-card-3 {
	grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}


#veg-card-4 {
	grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

#veg-card-5 {
	grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
}

#veg-card-6 {
	grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
}

.veg-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 4rem;
}



#tech {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
 	grid-template-rows: 1fr;
 	padding: 4rem;
 	grid-gap: 6rem;
}

#tech .numcard {
	grid-column-start: 1;
    grid-column-end: 2;
    padding: 3rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


#tech .listcard {
	grid-column-start: 2;
    grid-column-end: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 5rem;
}

#tech .listcard .border{
	background-color: var(--whiteColor);
	box-shadow: -4.8px 9.6px 4.8px rgba(0, 0, 95, 0.01), -2.4px 6px 3.6px rgba(0, 0, 95, 0.05), -1.2px 2.4px 2.4px rgba(0, 0, 95, 0.09), 0px 1.2px 1.2px rgba(0, 0, 95, 0.1), 0px 0px 0px rgba(0, 0, 95, 0.1), inset 0px -1px 1px rgba(0, 0, 95, 0.1);
	border-radius: 100px;

	height: 1px;
	width: 80%;
	margin-top: 3rem;
	margin-bottom: 3rem;
}

#facts {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
 	grid-template-rows: 1fr;
 	padding: 4rem;
 	grid-gap: 6rem;
 	text-align: center;
}

#fact-1 {
	background-image: url(../img/info/facts/farm.webp);
	background-position: center;
	background-size: cover;
	padding-top: 3rem;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

#fact-2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

#fact-3 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: left;

	background-image: url(../img/info/facts/korpus.webp);
	background-position: center;
	background-size: cover;
}


.fact-item {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.fact-item .headcard {
	line-height: 115%;

}

.headcard-r {
	text-align: right;
}


#fact-4 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.fact-card {
	padding: 4rem;
}

.fact-card img  {
	height: 20rem;
	margin-bottom: 2rem;
}

@media (min-width: 768px) and (max-width: 1199px) {
	#info .slider-mob {
		display: grid;
		overflow: auto;
	}

	#info .container {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	#info .show-des {
		display: none !important;
	}

	 	#info .container .head {
 		text-align: center;
 		width: 60%;
 		margin: 0
 	}
 	#info .container .subhead {
 		text-align: center;
 		width: 80%;
 	}

 	#info .card-wrapper {
 		margin-left: -6rem
 	}

	#vegan, #tech, #facts {
		grid-template-rows: 200px;
		grid-template-columns: 300px;
		padding: 10rem;
		padding-left:40rem;
		width: 200%;

	}

	#veg-card-0 {
		grid-column-start: 1;
	    grid-column-end: 2;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#veg-card-1 {
		grid-column-start: 2;
	    grid-column-end: 3;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#veg-card-2 {
		grid-column-start: 3;
	    grid-column-end: 4;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#veg-card-3 {
		grid-column-start: 4;
	    grid-column-end: 5;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#veg-card-4 {
		grid-column-start: 5;
	    grid-column-end: 6;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#veg-card-5 {
		grid-column-start: 6;
	    grid-column-end: 7;
	    grid-row-start: 2;
	    grid-row-end: 1;
	}

	#veg-card-6 {
		grid-column-start: 7;
	    grid-column-end: 8;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#veg-card-7 {
		grid-column-start: 8;
	    grid-column-end: 9;
	    grid-row-start: 1;
	    grid-row-end: 1;
	    		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}


	#veg-card-7 .card {
		padding: 6rem;
	} 

	#tech-card-0 {
		grid-column-start: 1;
	    grid-column-end: 2;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	.tech-card {
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4rem;
	}

	#tech-card-0 {
		grid-column-start: 1;
	    grid-column-end: 2;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#tech-card-1 {
		grid-column-start: 2;
	    grid-column-end: 3;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#tech-card-2 {
		grid-column-start: 3;
	    grid-column-end: 4;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#tech-card-3 {
		grid-column-start: 4;
	    grid-column-end: 5;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#tech-card-4 {
		grid-column-start: 5;
	    grid-column-end: 6;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#tech-card-5 {
		grid-column-start: 6;
	    grid-column-end: 7;
	    grid-row-start: 2;
	    grid-row-end: 1;
	}

	#tech-card-6 {
		grid-column-start: 7;
	    grid-column-end: 8;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	.fact-card {
		width: 150px
	}


	#fact-1 {
		grid-column-start: 1;
	    grid-column-end: 2;
	    grid-row-start: 1;
	    grid-row-end: 1;
	    padding: 3rem
	}

	#fact-2 {
		grid-column-start: 2;
	    grid-column-end: 3;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#fact-3 {
		grid-column-start: 3;
	    grid-column-end: 4;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#fact-4 {
		grid-column-start: 4;
	    grid-column-end: 5;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#facts, #tech, #vegan {
		width: 110%
	}

}


@media (max-width: 767px) {
	 #info .container .head {
 		text-align: left;
 		width: 60%;
 		margin-left: 6rem
 	}
 	#info .container .subhead {
 		text-align: left;
 		width: 80%;
 		margin-left: 6rem
 	}

 	#info .card-wrapper {
 		
 	}

	#vegan, #tech, #facts {
		grid-template-rows: 1fr;
		padding: 6rem 6rem 12rem;
		width: 100%;
		margin-top: -6rem
	}

	#vegan {
		grid-template-columns: repeat(7, 200px) 300px 100px
	}

	#tech {
		grid-template-columns: repeat(6, 200px) 100px
	}

	#facts {
		grid-template-columns: repeat(4, 200px) 100px
	}

	#veg-card-0 {
		grid-column-start: 1;
	    grid-column-end: 2;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#veg-card-1 {
		grid-column-start: 2;
	    grid-column-end: 3;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#veg-card-2 {
		grid-column-start: 3;
	    grid-column-end: 4;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#veg-card-3 {
		grid-column-start: 4;
	    grid-column-end: 5;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#veg-card-4 {
		grid-column-start: 5;
	    grid-column-end: 6;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#veg-card-5 {
		grid-column-start: 6;
	    grid-column-end: 7;
	    grid-row-start: 2;
	    grid-row-end: 1;
	}

	#veg-card-6 {
		grid-column-start: 7;
	    grid-column-end: 8;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#veg-card-7 {
		grid-column-start: 8;
	    grid-column-end: 9;
	    grid-row-start: 1;
	    grid-row-end: 1;
	    		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}


	#veg-card-7 .card {
		padding: 6rem;
	} 

	#tech-card-0 {
		grid-column-start: 1;
	    grid-column-end: 2;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	.tech-card {
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4rem;
	}

	#tech-card-0 {
		grid-column-start: 1;
	    grid-column-end: 2;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#tech-card-1 {
		grid-column-start: 2;
	    grid-column-end: 3;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#tech-card-2 {
		grid-column-start: 3;
	    grid-column-end: 4;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#tech-card-3 {
		grid-column-start: 4;
	    grid-column-end: 5;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#tech-card-4 {
		grid-column-start: 5;
	    grid-column-end: 6;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#tech-card-5 {
		grid-column-start: 6;
	    grid-column-end: 7;
	    grid-row-start: 2;
	    grid-row-end: 1;
	}

	#tech-card-6 {
		grid-column-start: 7;
	    grid-column-end: 8;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	.fact-card {
		width: 150px
	}


	#fact-1 {
		grid-column-start: 1;
	    grid-column-end: 2;
	    grid-row-start: 1;
	    grid-row-end: 1;
	    padding: 3rem
	}

	#fact-2 {
		grid-column-start: 2;
	    grid-column-end: 3;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#fact-3 {
		grid-column-start: 3;
	    grid-column-end: 4;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}


	#fact-4 {
		grid-column-start: 4;
	    grid-column-end: 5;
	    grid-row-start: 1;
	    grid-row-end: 1;
	}

	#facts, #tech, #vegan {
		width: 110%
	}

}


#callback .head {
	width: 500px;
}


#callback .container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 15rem;
}

#callback .container .head {
	width: 300px;
	text-align: center;
}

#callback .container form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#callback .container form .btn {
	margin-top: 3rem;
}



.callback-farm {
	object-fit: cover;
	height: 40vw;
	margin-bottom: 5rem;
}

.btn-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	width: 100vw;
	margin-bottom: 15rem;
}



@media (max-width: 767px) {
	.pic-wrapper .blur {
 		height: 20vh
 	}

 	.pic-wrapper .btn {
 		margin-left: 0
 	}

 	#callback .container {
 		padding-left: 0
 	}
}
 
.footer-container {
	width: 100%;
	background-color: var(--blackColor);
	margin: 0 !important;
	padding: 0 !important;
}

footer .container {
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.footer-logo {
	height: 50px;
	object-fit: cover;
}

.footer-head {
	color: var(--whiteColor)!important;
}

.footer-top  {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: row;
	margin-bottom: 10rem;
}

.footer-top .footer-head {
	margin-bottom: 1rem;
}

.footer-bottom {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-direction: row;
}

.footer-txt .txt {
	line-height: 130%;
}

.footer-bottom .txt{
	line-height: 130%;
}



.footer-txt {
	width:300px;
}

.footer-txt a {
	color: var(--grayColor) !important;
}

.footer-txt a:hover {
	text-decoration: none;
	color: var(--whiteColor) !important
}

@media (min-width: 767px) and (max-width: 1199px) {
	.footer-logo {
		height: 40px;
	}

	.footer-txt {
	width:240px;
	}
}

@media (max-width: 766px) {
	.footer-container {

	}

	.footer-logo {
		height: 30px;
		margin-bottom: 5rem
	}

	.footer-top .footer-txt {
		margin-bottom: 3rem
	}

	.footer-top {
		flex-direction: column;
		align-items: flex-start;
		margin-left: 6rem
	}

	.footer-bottom {
		flex-direction: column-reverse;
		align-items: flex-start;
		margin-left: 6rem

	}

	.footer-bottom .footer-txt {
		margin-bottom: 3rem
	}
}



 

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 1199px) {


	.container {
		max-width: 800px;
		overflow-x: hidden;
	}

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

	.container {
		max-width: 688px;

	}

}

